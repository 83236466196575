import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import PageTitleSection from "../../components/pagebuilder-parts/PageTitleSection"
import { Pagination } from "../../components/Pagination"
import ProductCard from "../../components/ProductCard"
import SEO from "../../components/SEO"
const ProductCategory = ({
  data: {
    category
  },
  pageContext: {nextPage, previousPage},
  location,
}) => {
  const {productCategories: {items}} = category
  return (
    <Layout>
      <SEO title={category?.title} />
      <PageTitleSection data={{ title : category?.title}} location={location} />
      <section className="category">
        <div className="row">
          {items && items.map((item, index) =>(<ProductCard key={item.id + `${index}`} {...item} />))}
        </div>
      </section>
      {(nextPage?.uri || previousPage?.uri !== null) && <Pagination previousPage={previousPage} nextPage={nextPage} />}
    </Layout>
  )
}

export default ProductCategory

export const ProductCategoryQuery = graphql`
  query productCategoryQuery($id: String!) {
    category: wpProductCategory(id: { eq: $id }) {
      title
      uri
      productCategories {
        items {
          ... on WpProduct {
            uri
            title
            product {
              startPrice
              description
              sizes {
                price
              }
              configuratorLink
            }
            featuredImage {
              node {
                altText
                localFile {
                  ...ProductImage
                }
              }
            }
          }
        }
      }
    }
  }
`
