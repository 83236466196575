import React from "react"
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi"
import { normalizePath } from "../utils/normalizePath"
import UniversalLink from "./UniversalLink"

export const Pagination = ({ previousPage = null, nextPage = null }) => {
  return (
    <section className="pagination">
      {previousPage?.uri && (
        <UniversalLink
          className="pagination__link"
          to={normalizePath(previousPage?.uri)}
        >
          <FiChevronsLeft className="pagination__icon"/>
          {previousPage.title}
        </UniversalLink>
      )}
      {nextPage?.uri && (
        <UniversalLink
          className="pagination__link"
          to={normalizePath(nextPage?.uri)}
        >
          {nextPage.title}
          <FiChevronsRight className="pagination__icon" />
        </UniversalLink>
      )}
    </section>
  )
}
